<template>
  <div>
    <h1 style="text-align: center">{{ title }}</h1>
    <el-form
      class="form"
      ref="form"
      label-width="90px"
      style="box-sizing: border-box; overflow: hidden; padding-right: 20px"
    >
      <el-row :gutter="20">
        <el-col
          :span="12"
          v-for="(item, index) in printlist"
          :key="index + item.Keys"
        >
          <el-form-item
            :label="item.Key"
            v-if="item.type == '1' && item.Keys !== 'Time'"
          >
            <CustomerSearchSelect
              v-if="item.Keys == 'CustomerName'"
              @select="CustomerSelect($event, item)"
              :CompanyId="CompanyId"
              v-model="item.value"
            />
            <!-- v-model="entity[item.Keys]" -->
            <el-input v-else v-model="item.value"></el-input>
          </el-form-item>
        </el-col>
        <el-col v-for="(item, index) in printlist" :key="index" :span="24">
          <el-form-item v-if="item.Keys == 'text'" label="">
            <span v-for="(im, ix) in item.value.split('{}')" :key="ix">
              {{ im }}
              <el-input
                class="dInput"
                v-if="ix !== item.value.split('{}').length - 1"
                v-model="dynamic['P' + index + 'F' + ix]"
              ></el-input>
            </span>
          </el-form-item>
        </el-col>
      </el-row>
      <div>
        <div></div>
      </div>
    </el-form>
    <div style="height: 50px"></div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <el-button @click="drawer = false">取消</el-button>
      <el-button type="primary" :loading="btnLoading" @click="handleSubmit"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import op from "@/utils/OperatorCache";
import CustomerSearchSelect from "@/components/CustomerSearchSelect.vue";
import CompanySearchSelect from "@/components/CompanySearchSelect.vue";
export default {
  components: {
    CompanySearchSelect,
    CustomerSearchSelect,
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      entity: {},
      CustomerSign: "",
      btnLoading: false,
      title: "",
      printlist: [],
      dynamic: {},
      CompanyId: "",
      newEntity: {},
      prop: [],
    };
  },
  mounted() {
    if (this.$route.query.token) {
      sessionStorage.setItem("token", this.$route.query.token);
    }
    if (this.$route.query.prop) {
      console.log(this.$route.query.prop);
      this.prop = this.$route.query.prop.split(",");
    }
    op.updateInfo(() => {
      this.userInfo = op.info;
    });
    this.$http
      .post(
        "/Base/Base_Reconciliation/GetCompanyTemp?CompanyId=" +
          this.$route.query.CompanyId,
        {}
      )
      .then((res) => {
        console.log(res);
        if (res.Success) {
          this.openForm(
            "新增对账函",
            res.Data.Content,
            this.$route.query.CompanyId
          );
        } else {
          this.$message.error(res.Msg);
          setTimeout(() => {
            this.$router.replace("/Reconciliation");
          }, 1000);
        }
      });
  },
  methods: {
    // 公司选择
    CompanySelect(row) {
      console.log(row);
    },
    CustomerSelect(row, item) {
      console.log(row);
      item.value = row.cCusName;
      this.newEntity.CustomerId = row.Id;
    },
    init() {
      this.entity = {};
      this.newEntity = {};
      this.printlist = [];
      this.dynamic = {};
      this.drawer = true;
    },
    openForm(title, content, CompanyId) {
      this.init();
      this.CompanyId = CompanyId;
      this.title = title;
      if (this.title == "新增对账函") {
        this.entity = content;
        this.printlist = JSON.parse(content).printlist;
        this.printlist = this.printlist.sort((a, b) => a.sort - b.sort);
        this.printlist.forEach((item, index) => {
          if (item.Keys == "text") {
            item.value.split("{}").forEach((im, ix) => {
              if (ix !== item.value.split("{}").length - 1) {
                this.$set(this.dynamic, "P" + index + "F" + ix, "");
              }
            });
          } else if (item.Keys == "CustomerName") {
            this.$set(item, "value", this.prop[0]);
          }
        });
        let keyIndex = 0;
        for (const key in this.dynamic) {
          keyIndex++;
          if (!isNaN(Number(this.prop[keyIndex]))) {
            this.prop[keyIndex] = Number(this.prop[keyIndex]).toFixed(4)
          }
          this.$set(this.dynamic, key, this.prop[keyIndex]);
        }
        this.newEntity.cCusName = this.prop[0]
      } else {
        this.getDetailList(content);
      }
    },
    getDetailList(id) {
      this.$http
        .post("/Base/Base_Reconciliation/GetRecDetail?Id=" + id)
        .then((res) => {
          console.log(res);
          if (res.Success) {
            this.newEntity = res.Data;
            this.entity = res.Data.Content;
            this.printlist = JSON.parse(res.Data.Content).printlist;
            JSON.parse(res.Data.SpanContent).printlist.forEach((item) => {
              if (item.Keys != "text") {
                let idx = this.printlist.findIndex(
                  (im) => item.Keys == im.Keys
                );
                if (idx !== -1) {
                  this.$set(this.printlist[idx], "value", item.value);
                }
              }
            });
            this.dynamic = JSON.parse(res.Data.SpanValue);
          }
        });
    },
    handleClose(done) {
      done();
    },
    handleSubmit() {
      console.log(this.dynamic);
      // input 拼接赋值
      this.printlist.forEach((item, index) => {
        if (item.Keys == "text") {
          let str = "";
          item.value.split("{}").forEach((im, ix) => {
            if (ix !== item.value.split("{}").length - 1) {
              str +=
                im +
                '<span class="a">' +
                (this.dynamic["P" + index + "F" + ix] || "") +
                "</span>";
            } else {
              str += im;
            }
          });
          item.value = str;
        }
      });
      // 邮箱html模板
      let html = JSON.parse(JSON.stringify(this.printlist))
        .filter((item) => item.type != 3)
        .sort((a, b) => a.sort - b.sort);
      html.forEach((item) => {
        if (item.Keys == "Title") item.value = `<h1>${item.value}</h1>`;
        else if (item.Keys == "text")
          item.value = `<p style="text-indent: 2em;">${item.value}</p>`;
        else if (item.Keys == "CustomerName")
          item.value = `<p>收货方: ${item.value}</p>`;
      });
      let newHtml = html.map((item) => item.value).join("");
      // 赋值
      this.btnLoading = true;
      this.newEntity.Company = this.CompanyId; // 公司
      this.newEntity.content = this.entity; //初始模板
      let SpanContent = JSON.parse(this.entity);
      SpanContent.printlist = this.printlist;
      this.newEntity.HtmlContent = `<div>${newHtml}</div>`;
      this.newEntity.SpanContent = JSON.stringify(SpanContent); // 拼接数据
      this.newEntity.SpanValue = JSON.stringify(this.dynamic); // 段落填写数据
      this.$http
        .post("/Base/Base_Reconciliation/SaveData", this.newEntity)
        .then((res) => {
          this.btnLoading = false;
          if (res.Success) {
            this.$message.success("操作成功");
            setTimeout(() => {
              this.$router.replace("/Reconciliation");
            }, 1000);
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.dInput {
  width: 200px;
}
</style>